.loadingView {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
    font-size: 1.5em;
    animation-name: fadeIn;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

.loadingView span {
    display: block;
    color: #fff;
    font-size: 0.915em;
    line-height: 1.2;
    font-family: "RO Sans", Calibri, sans-serif;
}

.loaderIcon {
    width: 5em;
    margin: 0 0 1em;
    transform-origin: center;
    animation: rotate 1.5s linear infinite;
}

.loaderIcon circle {
    fill: none;
    stroke: #fff;
    stroke-width: 1;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dashoffset: -125px;
    }
}
