.tooltip {
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    margin-left: 2.5rem;
    background-color: #01689b;
    overflow: hidden;
    align-self: flex-start;
    border: 0;
    padding: 0;
    transition: background-color 0.3s ease;
}

.tooltip:hover {
    background-color: #01496d;
}

.tooltip:focus {
    outline: 2px dotted #000;
    z-index: 1010;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

.tooltipIcons {
    transform: translateY(0);
    transition: transform 0.5s;
}

.tooltipActive .tooltipIcons {
    transform: translateY(-2.5rem);
}

.tooltipIcon {
    width: 2.5rem;
    height: 2.5rem;
    min-height: 2.5rem;
    font-size: 18px;
}

.tooltipText {
    flex: 0 0 calc(100% - 5rem);
    padding-top: 15px;
    animation-name: tooltip;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    font-size: 1.125rem;
    line-height: 1.33333;
}

@keyframes tooltip {
    0% {
        opacity: 0;
        transform: translateY(-5px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
