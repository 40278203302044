.range {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background: #b4b4b4;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}

.range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #01689b;
    cursor: pointer;
}

.range::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #01689b;
    cursor: pointer;
}

.range:focus::-webkit-slider-thumb {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

.range:focus::-moz-range-thumb {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}
