.logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.logoWrapper {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: -16px;
    margin-left: -16px;
    justify-content: center;
}

.logo picture {
    display: inline-flex;
}

.logo img {
    width: inherit;
    max-width: 1168px;
}

.logo img {
    margin-left: -50px;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
}

header a:focus .logo img {
    outline: 2px dotted #000;
    z-index: 1010;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

.meta {
    font-size: 0.8888888889rem;
    line-height: 1.6875;
    font-weight: normal;
    color: #535353;
    margin: 0 !important;
    padding: 0.75rem 0.5rem 0 0;
    text-align: right;
}

.form {
    margin-top: 0;
    margin-bottom: 28px;
}

.error {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 1.125rem;
    line-height: 1.3333333333;
    position: relative;
    padding-left: calc(24px + 2rem - 4px);
    background: #f9dfdd;
}

.error p {
    margin-bottom: 0 !important;
}

.error:before {
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: block;
    width: 24px;
    height: 24px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23d52b1e;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon error%3C/title%3E%3Ccircle class='a' cx='16' cy='16' r='16'/%3E%3Cpath class='b' d='M20.75,9.46,16,13.68,11.25,9.46a1.33,1.33,0,0,0-1.76,0,1.3,1.3,0,0,0,0,1.74L13.68,16,9.46,20.75a1.25,1.25,0,0,0,1.77,1.77L16,18.32l4.75,4.22a1.25,1.25,0,0,0,1.77-1.77L18.32,16l4.22-4.75a1.28,1.28,0,0,0,0-1.74,1.31,1.31,0,0,0-1.76,0M16,16l0,0,0,0h0l0,0Z'/%3E%3C/svg%3E");
    background-size: contain
}