.mainWrapper {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: flex-start;
    margin-right: -16px;
    margin-left: -16px;
}

@media (min-width: 768px) {
    .mainWrapper {
        min-height: calc(100vh - 577px);
    }
}

.external {
    position: relative;
    padding-left: 1rem;
}

.external:before {
    content: "\EA1E";
    font-family: "RO Icons";
    font-size: 0.7rem;
    font-weight: normal;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 5px;
    left: 2px;
}

.content {
    padding-top: 3rem;
    flex-grow: 0;
    flex-shrink: initial;
    flex-basis: 100%;
    max-width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    max-width: 100vw;
    margin: 0 auto;
}

.content ul {
    list-style-type: initial;
    padding-left: 1rem;
}

.content > :last-child {
    margin-bottom: 3rem;
}

.content.contentArticle p {
    margin-top: 0;
    margin-bottom: 1.25rem;
}

@media (min-width: 768px) {
    .contentArticle {
        flex-grow: 0;
        flex-shrink: initial;
        flex-basis: 75%;
        max-width: 75%;
        padding-right: 16px;
        padding-left: 16px;
    }
}

@media (min-width: 992px) {
    .contentArticle {
        flex-grow: 0;
        flex-shrink: initial;
        flex-basis: 66.6666666667%;
        max-width: 66.6666666667%;
        padding-right: 16px;
        padding-left: 16px;
    }
}

.intro {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}

.intro p,
.intro li {
    font-size: 1.6018066406rem;
    line-height: 1.4046639232;
}

.intro p a.external,
.intro li a.external {
    display: inline;
}

@media (min-width: 992px) {
    .intro p a.external:before,
    .intro li a.external:before {
        /* height: 36px; */
        /* top: -3px; */
    }
}

.intro p,
.intro ul,
.intro ol {
    margin-bottom: 1rem;
}

.intro p + h2,
.intro p + h3,
.intro ul + h2,
.intro ul + h3,
.intro ol + h2,
.intro ol + h3 {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.intro li:before {
    width: 9px;
    height: 9px;
    top: 11.5px;
}

.contentArticle > .intro + h2 {
    margin-top: 3rem;
}

.contentArticle h2 {
    margin-bottom: 0.75rem;
}

.contentArticle h2:focus {
    outline: 2px dotted #01689b;
}

.contentArticle p a.external:before,
.contentArticle li a.external:before {
    display: inline-flex;
    align-items: center;
    left: 1px;
    /* height: 22.4px; */
}

/* @media (min-width: 768px) {
    .contentArticle p a.external:before,
    .contentArticle li a.external:before {
        height: 25.2px;
    }
}

@media (min-width: 992px) {
    .contentArticle p a.external:before,
    .contentArticle li a.external:before {
        height: 28px;
    }
} */

.hideMobile {
    display: none;
}

@media (min-width: 768px) {
    .hideMobile {
        display: block;
    }
}

.hideDesktop {
    display: block;
}

@media (min-width: 768px) {
    .hideDesktop {
        display: none;
    }
}

.assistive {
    position: absolute !important;
    display: inline-block !important;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.icon {
    font-size: inherit;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "RO Icons";
}

.iconQuestion::after {
    content: "\EA1F";
}

.iconCross::after {
    content: "\EA05";
}

.iconDownload::after {
    content: "\EA1D";
}

.iconZoom::after {
    content: "\EA0A";
}

.form {
    background-color: #f3f3f3;
    padding: 1.875rem 0 0.3125rem 1.875rem;
    margin-top: 1.875rem;
}

.formRow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 0 1rem 0 ;
    border: none;
    padding: 0;
}

.formRow small {
    margin-top: 5px;
    font-size: 1rem;
}

.formRow + .formRow {
    margin-top: 1rem;
}

.formLabel {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    font-size: 1.25rem;
    margin-bottom: 0.3125rem;
    font-weight: bold;
}

.formInput {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc(100% - 5rem);
    flex: 0 0 calc(100% - 5rem);
    max-width: calc(100% - 5rem);
    line-height: 0;
}

.formInputInline label {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 0.625rem;
}

.formInputInline label + label {
    margin-top: 0;
    margin-left: 0;
}
