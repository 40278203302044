.poster {
    --width: 1200px;
    width: var(--width);
    height: calc(var(--width) * 0.5225);
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    cursor: move;
    transition: 500ms background;
    pointer-events: none;
}

.hasPicture {
    cursor: move;
    pointer-events: auto;
}

.isGrabbing {
    cursor: grabbing;
}

.content {
    height: 100%;
    max-width: 40.67%;
    background-color: #76d2b6;
    position: relative;
    flex: 0 0 40.67%;
    z-index: 2;
    pointer-events: none;
}

.contentInner {
    padding-top: calc(var(--width) * 0.013);
    padding-left: calc(var(--width) * 0.04583333333);
    padding-right: calc(var(--width) * 0.04583333333);
    color: #00305e;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.quote {
    position: relative;
    font-size: calc(calc(var(--width) * 0.02666666667));
    line-height: 1.25;
    font-weight: bold;
}

.breakWord {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
}

.quote::before {
    content: "“";
    position: absolute;
    left: calc((var(--width) * 0.01259166667) * -1);
}

.quote::after {
    content: "”";
    position: relative;
}

.info {
    margin-top: calc(var(--width) * 0.01953166667);
}

.name {
    font-weight: bold;
    font-size: calc(calc(var(--width) * 0.02333333333) * var(--fontScale));
    line-height: 1.1785714286;
}

.function {
    font-size: calc(calc(var(--width) * 0.02333333333) * var(--fontScale));
    line-height: 1.0357142857;
    margin-top: calc(var(--width) * 0.003138333333);
}

.link {
    background-color: #00305e;
    color: #fff;
    font-size: calc(calc(var(--width) * 0.02416666667));
    line-height: 1.0344827586;
    position: absolute;
    bottom: 0;
    padding-top: calc(var(--width) * 0.01178385);
    padding-left: calc(var(--width) * 0.01423176667);
    padding-right: calc(var(--width) * 0.01423176667);
    padding-bottom: calc(var(--width) * 0.01423176667);
    text-align: center;
    left: calc(var(--width) * 0.03455733333);
    right: calc(var(--width) * 0.03455733333);
}

.roLogo {
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(var(--width) * 0.07833333333);
    transform: translateX(-50%);
    z-index: 1;
    height: auto;
    pointer-events: none;
}

.campagneLogo {
    position: absolute;
    bottom: -2px;
    right: calc(var(--width) * 0.0175);
    width: calc(var(--width) * 0.3091);
    z-index: 1;
    height: auto;
    pointer-events: none;
}

.pictureWrapper {
    height: 100%;
    position: relative;
    max-width: 59.33%;
    flex: 0 0 59.33%;
    background-color: #00305e;
    z-index: 0;
    display: flex;
    justify-content: center;
}

.picture {
    height: 100%;
    width: auto;
    top: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;
}

.pixelGrid {
    height: 100%;
    width: 18.33%;
    background: #76d2b6;
    background: url("./assets/post-pixelgrid.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    opacity: 0.9;
    position: absolute;
    z-index: 1;
    left: 40.67%;
    top: 0;
    bottom: 0;
    pointer-events: none;
}
