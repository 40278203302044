.poster {
    --width: 400px;
    width: var(--width);
    height: calc(var(--width));
    position: relative;
    overflow: hidden;
    transition: 500ms background;
    display: flex;
    justify-content: center;
    background-color: #00305e;
    pointer-events: none;
}

.hasPicture {
    cursor: move;
    pointer-events: auto;
}

.isGrabbing {
    cursor: grabbing;
}

.rounded {
    border-radius: var(--width);
}

.campagneLogo {
    position: relative;
    width: calc(var(--width) * 0.9311111111);
    z-index: 2;
    margin-bottom: calc(var(--width) * 0.08444444444);
    margin-top: auto;
    height: auto;
    pointer-events: none;
}

.pictureWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    background-color: #00305e;
}

.picture {
    height: 100%;
    width: auto;
    top: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;
}

.gradient {
    position: absolute;
    height: 50%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 48, 94, 0) 0%, #00305E 100%);
}