.preview-container {
    user-select: none;
    margin-top: 16px;
}

.preview-container .size-linkedin-post,
.preview-container .size-linkedin-banner,
.preview-container .size-linkedin-profile-picture {
    --width: calc(100vw - 32px);
}

@media screen and (min-width: 576px) {
    .preview-container .size-linkedin-profile-picture {
        --width: 400px;
    }
}

@media screen and (min-width: 768px) {
    .preview-container .size-linkedin-post,
    .preview-container .size-linkedin-banner,
    .preview-container .size-linkedin-profile-picture {
        --width: 350px;
    }
}

@media screen and (min-width: 824px) {
    .preview-container .size-linkedin-post,
    .preview-container .size-linkedin-banner,
    .preview-container .size-linkedin-profile-picture {
        --width: 400px;
    }
}

@media screen and (min-width: 880px) {
    .preview-container .size-linkedin-post,
    .preview-container .size-linkedin-banner,
    .preview-container .size-linkedin-profile-picture {
        --width: 450px;
    }
}

@media screen and (min-width: 992px) {
    .preview-container .size-linkedin-post,
    .preview-container .size-linkedin-banner {
        --width: 500px;
    }
}

@media screen and (min-width: 1200px) {
    .preview-container .size-linkedin-post,
    .preview-container .size-linkedin-banner {
        --width: 600px;
    }
}

.preview-render {
    position: absolute;
    left: -9999px;
    user-select: none;
}

.preview-render .size-linkedin-post {
    --width: 2400px; /* 1200px */
}

.preview-render .size-linkedin-banner {
    --width: 3168px; /* 1584px */
}

.preview-render .size-linkedin-profile-picture {
    --width: 800px; /* 400px */
}

#dialog-content .preview-container .size-linkedin-banner {
    --width: calc(100vw - 32px);
}

@media screen and (min-width: 1200px) {
    #dialog-content .preview-container .size-linkedin-banner {
        --width: 1170px;
    }
}

#dialog-content .preview-container {
    margin: 0;
}

#dialog-content .carousel__button {
    width: auto;
    font-size: 1.0625rem;
}

#dialog-content .carousel__button:focus {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 0;
}

#dialog-content .close {
    font-size: inherit;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "RO Icons";
    margin-right: 0.75rem;
}

#dialog-content .close::after {
    content: "\EA05";
}
