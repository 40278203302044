.textareaInputGroup {
    position: relative;
    width: 100%;
}

.textareaInput {
    padding-left: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border: 1px solid dimgray;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1.26562rem;
    line-height: 1;
    font-family: "RO Sans", Calibri, sans-serif;
    display: block;
    width: 100%;
    border-radius: 0;
    resize: vertical;
}

.textareaInput:focus {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

.textareaInputLimited {
    padding-right: 64px;
}

.characterCount {
    position: absolute;
    right: 5px;
    top: 1rem;
    font-size: 14px;
    color: #000;
    z-index: 2;
    opacity: 0.85;
}

.characterCount span + span:before {
    content: "/";
    margin: 0 2px;
}
