.preview {

}

.previewNav {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
}
