.fileInputGroup label {
    display: flex;
    cursor: pointer;
}

.fileInputGroup label:hover .fileInputButton {
    background-color: #01496d;
}

.fileInputButton {
    flex: 0 0 auto;
}

.fileInput {
    display: none;
}

.fileResult {
    align-items: center;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    display: flex;
    z-index: 1;
    border: 1px solid dimgray;
    background-color: #fff;
    appearance: none;
    min-height: 2.75rem;
    overflow: hidden;
    border-left: none;
}

.fileResultText {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 1rem;
    font-size: 1.26562rem;
    line-height: 1;
    color: #575757;
}

