.poster {
    --width: 1584px;
    width: var(--width);
    height: calc(var(--width) * 0.25);
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden;
    cursor: move;
    transition: 500ms background;
    pointer-events: none;
}

.hasPicture {
    cursor: move;
    pointer-events: auto;
}

.isGrabbing {
    cursor: grabbing;
}

.gradient {
    height: 100%;
    width: 40%;
    background: #00305e;
    background: linear-gradient(
        90deg,
        rgba(0, 48, 94, 1) 0%,
        rgba(0, 48, 94, 1) 75%,
        rgba(0, 48, 94, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.pixelGrid {
    height: 100%;
    width: 53%;
    background: #76d2b6;
    background: url("./assets/banner-pixelgrid.png");
    background-size: contain;
    background-position: center 0;
    background-repeat: no-repeat;
    opacity: 0.9;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    bottom: 0;
}

.link {
    top: 0;
    background-color: #00305e;
    color: #fff;
    font-size: calc(var(--width) * 0.02);
    line-height: 1.24;
    position: absolute;
    padding-top: calc(var(--width) * 0.01333333333);
    padding-left: calc(var(--width) * 0.015);
    padding-right: calc(var(--width) * 0.015);
    padding-bottom: calc(var(--width) * 0.01166666667);
    text-align: center;
    left: calc(var(--width) * 0.15);
    width: calc(var(--width) * 0.3);
    z-index: 3;
}

.campagneLogo {
    position: absolute;
    bottom: -2px;
    right: calc(var(--width) * 0.1283333333);
    width: calc(var(--width) * 0.2333333333);
    color: #00305e;
    z-index: 3;
    height: auto;
}

.pictureWrapper {
    height: 100%;
    position: relative;
    max-width: 70.28%;
    flex: 0 0 70.28%;
    background-color: #00305e;
    z-index: 0;
    display: flex;
    justify-content: center;
    margin-left: auto;
}

.picture {
    height: 100%;
    width: auto;
    top: 0;
    bottom: 0;
    user-select: none;
    pointer-events: none;
}
