.button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1.26562rem;
    color: #fff;
    background-color: #01689b;
    padding: 0.5rem 1rem;
    border: 0;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;
    font-family: "RO Sans", Calibri, sans-serif;
    min-height: 2.75rem;
    display: inline-flex;
    align-items: center;
}

.button:hover {
    background-color: #01496d;
}

.button:disabled {
    background-color: #bdbdbd;
    color: #676767;
    cursor: not-allowed;
}

.button:focus {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

.button span {
    margin-right: 0.75rem;
    font-size: 1.0625rem;
}
