.breadcrumb {
    display: flex;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
    margin: 0 16px;
    padding: 1.5rem 0rem;
    padding-bottom: 0.75rem;
    font-size: 1.6018066406rem;
    line-height: 1.0925163847;
    color: #000;
}

@media (min-width: 576px) {
    .breadcrumb {
        margin: 0 64px 0 16px;
    }
}

.breadcrumb > a,
.breadcrumb > span {
    padding-bottom: 0.75rem;
    display: initial;
    align-items: center;
    color: #000;
}

@media (min-width: 576px) {
    .breadcrumb > a,
    .breadcrumb > span {
        display: flex;
    }
}

.breadcrumb > a {
    position: relative;
    margin-right: 1rem;
    text-decoration: none;
}

@media (min-width: 576px) {
    .breadcrumb > a {
        margin-right: 2rem;
    }
}

.breadcrumb > a:after {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    content: "\EA0B";
    font-family: "RO Icons";
    font-size: 0.7em;
    font-weight: normal;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 12px;
    height: 28.8325195312px;
    position: relative;
    right: -8px;
    bottom: -1px;
}

@media (min-width: 576px) {
    .breadcrumb > a:after {
        position: absolute;
        right: -22px;
        bottom: initial;
    }
}

@media (min-width: 576px) and (-ms-high-contrast: none),
    (min-width: 576px) and (-ms-high-contrast: active) {
    .breadcrumb > a:after *::-ms-backdrop {
        bottom: 0.75rem;
    }

    .breadcrumb > a:after {
        bottom: 0.75rem;
    }
}

.breadcrumb > a:hover,
.breadcrumb > a:visited {
    color: #000;
}

.breadcrumb > a:hover:after,
.breadcrumb > a:visited:after {
    color: #000;
}

.breadcrumb > a:last-child:after {
    content: "";
    text-decoration: none;
}

.breadcrumb > a:hover {
    text-decoration: underline;
}

.breadcrumb > span {
    font-weight: bold;
}
