@font-face {
    font-family: "RO Sans";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("./fonts/RO-SansWebText-Regular.woff2") format("woff2"),
        url("./fonts/RO-SansWebText-Regular.woff") format("woff");
}

@font-face {
    font-family: "RO Sans";
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    src: url("./fonts/RO-SansWebText-Italic.woff2") format("woff2"),
        url("./fonts/RO-SansWebText-Italic.woff") format("woff");
}

@font-face {
    font-family: "RO Sans";
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url("./fonts/RO-SansWebText-Bold.woff2") format("woff2"),
        url("./fonts/RO-SansWebText-Bold.woff") format("woff");
}

@font-face {
    font-family: "ErnestineOT";
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url("./fonts/ErnestineOT-Bold.woff2") format("woff2"),
        url("./fonts/ErnestineOT-Bold.woff") format("woff");
}

@font-face {
    font-family: "RO Icons";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("./fonts/ro-icons-2.1.woff2") format("woff2"),
        url("./fonts/ro-icons-2.1.woff") format("opentype"),
        url("./fonts/ro-icons-2.1.ttf") format("truetype");
}
