.wrapper {
    margin: 4rem 0rem 2.5rem 0rem;
    width: 100%;
}

@media (min-width: 768px) {
    .wrapper {
        margin-top: 3rem;
    }
}

.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-right: 16px;
    padding-left: 16px;
}

.row {
    display: flex;
    margin: 0 -16px;
    flex-direction: column;
}

@media (min-width: 768px) {
    .row {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.content p {
    font-size: 16px;
    line-height: 145%;
}

.formWrapper {
    width: 100%;
    padding: 0 16px;
    max-width: 549px;
    flex: 1 1;
}

.preview {
    padding: 0 16px;
    top: 0;
    margin-top: 40px;
}

@media (min-width: 768px) {
    .preview {
        margin-top: 0px;
        position: sticky;
    }
}

.dialog {
    display: none;
    padding: 0;
}
