.siteFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    background-color: #8fcae7;
}

.siteFooterWrapper {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: -16px;
    margin-left: -16px;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.siteFooterColumn {
    flex-grow: 0;
    flex-shrink: initial;
    flex-basis: 50%;
    max-width: 50%;
    padding-right: 16px;
    padding-left: 16px;
}

@media (min-width: 768px) {
    .siteFooterColumn {
        flex-grow: 0;
        flex-shrink: initial;
        flex-basis: 25%;
        max-width: 25%;
        padding-right: 16px;
        padding-left: 16px;
    }
}

@media (min-width: 1200px) {
    .siteFooterColumn {
        flex-grow: 0;
        flex-shrink: initial;
        flex-basis: 16.6666666667%;
        max-width: 16.6666666667%;
        padding-right: 16px;
        padding-left: 16px;
    }
}

.siteFooterColumn h2 {
    color: #000;
}

.siteFooterColumn ul a {
    color: #000;
}

.siteFooterColumn ul a:hover,
.siteFooterColumn ul a:focus,
.siteFooterColumn ul a:visited::before {
    color: #000;
}

.siteFooterColumn h2,
.siteFooterColumn li,
.siteFooterColumn a,
.siteFooterColumn a:hover {
    color: #000;
}

.siteFooterColumn h2 {
    font-size: 1.6018066406rem;
    line-height: 1.2485901539;
    font-weight: normal;
    margin-bottom: 1rem;
}

.siteFooterColumn ul {
    margin-bottom: 0;
    list-style: none !important;
    margin-left: 0 !important;
}

.siteFooterColumn ul li,
.siteFooterColumn ul li > h2,
.siteFooterColumn ul a > h3,
.siteFooterColumn ul a > h4 {
    font-size: 1.125rem;
    line-height: 19.2px;
    font-weight: normal;
}

@media (min-width: 768px) {
    .siteFooterColumn ul li,
    .siteFooterColumn ul li > h2,
    .siteFooterColumn ul a > h3,
    .siteFooterColumn ul a > h4 {
        line-height: 21.6px;
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul li,
    .siteFooterColumn ul li > h2,
    .siteFooterColumn ul a > h3,
    .siteFooterColumn ul a > h4 {
        line-height: 24px;
    }
}

.siteFooterColumn ul li,
.siteFooterColumn ul a {
    margin-bottom: 0.75rem;
}

.siteFooterColumn ul li > a {
    margin-bottom: 0;
}

.siteFooterColumn ul a {
    padding-left: calc((8.4px + 0.75rem) * 0.8);
    position: relative;
    display: block;
    text-decoration: none;
    font-size: inherit;
    line-height: inherit;
    font-weight: normal;
    padding-top: 6.4px;
    padding-bottom: 6.4px;
}

@media (min-width: 768px) {
    .siteFooterColumn ul a {
        padding-left: calc((8.4px + 0.75rem) * 0.9);
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul a {
        padding-left: calc((8.4px + 0.75rem) * 1);
    }
}

@media (min-width: 768px) {
    .siteFooterColumn ul a {
        padding-top: 7.2px;
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul a {
        padding-top: 8px;
    }
}

@media (min-width: 768px) {
    .siteFooterColumn ul a {
        padding-bottom: 7.2px;
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul a {
        padding-bottom: 8px;
    }
}

.siteFooterColumn ul a:before {
    display: inline-flex;
    align-items: center;
    content: "\EA0C";
    font-family: "RO Icons";
    font-size: 0.7rem;
    font-weight: normal;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 6.72px;
    padding-top: 6.4px;
    height: 25.6px;
    text-indent: -0.1em;
    position: absolute;
    top: -1px;
    left: 0;
}

@media (min-width: 768px) {
    .siteFooterColumn ul a:before {
        width: 7.56px;
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul a:before {
        width: 8.4px;
    }
}

@media (min-width: 768px) {
    .siteFooterColumn ul a:before {
        padding-top: 7.2px;
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul a:before {
        padding-top: 8px;
    }
}

@media (min-width: 768px) {
    .siteFooterColumn ul a:before {
        height: 28.8px;
    }
}

@media (min-width: 992px) {
    .siteFooterColumn ul a:before {
        height: 32px;
    }
}

.siteFooterColumn ul a:hover {
    text-decoration: underline;
}

.siteFooterColumn ul a:hover:before {
    text-decoration: none;
}

.siteFooterColumn ul a.external:before {
    content: "\EA1E";
    font-family: "RO Icons";
    font-size: 0.595rem;
    font-weight: normal;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.siteFooterColumn ul li {
    margin-bottom: 0;
}

.siteFooterColumn ul a:visited:before {
    color: #000;
}

@media (min-width: 768px) {
    .siteFooterColumn:first-child {
        margin-left: 50%;
    }
}

@media (min-width: 1200px) {
    .siteFooterColumn:first-child {
        margin-left: 66.666667%;
    }
}

@media print {
    .siteFooter {
        display: none;
    }
}
