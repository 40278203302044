.radioInput {
    margin-top: 0;
    margin-right: 0.5rem;
    margin-left: 0;
    width: 1rem;
    height: 1.5rem;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 1rem;
    font-size: 120%;
    position: relative;
    min-width: 0.9375rem;
    min-height: 0.9375rem;
}

.radioInput:focus {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

.radioInputWrapper {
    padding-top: 0;
    display: flex;
    font-size: 1.26562rem;
    line-height: 24px;
    align-items: center;
}

.radioInputWrapper:not(:last-of-type) {
    margin-bottom: 0.5rem;
}
