.navbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    width: auto;
    min-height: 70px;
    background-color: #8fcae7;
}

.navbarWrapper {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: center;
    justify-content: flex-start;
    margin-right: -16px;
    margin-left: -16px;
    position: relative;
}

@media print {
    .navbar {
        display: none;
    }
}
