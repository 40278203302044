.selectInputWrapper {
    position: relative;
}

.selectInputWrapper:after {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "RO Icons";
    content: "";
    width: 2.75rem;
    height: 2.75rem;
    color: #01689b;
    z-index: 2;
    font-size: 15px;
    pointer-events: none;
}

.selectInput {
    min-height: 2.75rem;
    border: 1px solid dimgray;
    border-radius: 0;
    background-color: #fff;
    font-size: 1.26562rem;
    line-height: 2.5rem;
    font-family: "RO Sans", Calibri, sans-serif;
    display: block;
    width: 100%;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-left: 1rem;
    padding-right: 2.75rem;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

.selectInput:focus {
    outline: 2px dotted #000;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}
