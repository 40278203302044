*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    font-family: "RO Sans", Calibri, sans-serif;
    font-size: 80%;
}

@media (min-width: 768px) {
    :root {
        font-size: 90%;
    }
}

@media (min-width: 992px) {
    :root {
        font-size: 100%;
    }
}

html {
    -webkit-text-size-adjust: 100%;
    line-height: 1.15;
}

body {
    font-family: "RO Sans", Calibri, sans-serif;
    color: #000;
    margin: 0;
    overflow-x: hidden;
}

body {
    font-weight: normal;
    color: #000;
}

body,
p,
li {
    font-size: 1.265625rem;
    line-height: 1.3827160494;
}

a {
    color: #01689b;
    background-color: transparent;
}

a:hover {
    text-decoration: underline;
    color: #01496d;
}

a:visited {
    color: #848;
}

a:visited:before,
a:visited:after {
    color: #848;
}

a:focus {
    outline: 2px dotted #000;
    z-index: 1010;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 2px #fff;
    box-shadow: 0 0 0 2px #fff;
}

a[href^="tel:"]:before {
    content: "";
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    background-color: currentColor;
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cg id='ICOON'%3E%3Cpath d='M185.2,163.1c-10.2,10.2-13.2,24.9-21.3,33.1c-8,8-39.4-17.1-63.3-40.7c-23.7-23.9-48.7-55.3-40.7-63.3 c8.1-8.1,22.8-11,33.1-21.3c10.2-10.2-33.5-58.6-40.4-65.5S35.7-2.2,27.8,5.7C-17.5,51-8.1,113.3,60.8,186.3l-0.2,0.1 c1.5,1.5,9.1,8.8,9.1,8.8c72.9,68.9,135.3,78.4,180.6,33c7.9-7.9,7.1-17.8,0.3-24.6C243.8,196.7,195.4,152.9,185.2,163.1z' /%3E%3C/g%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cg id='ICOON'%3E%3Cpath d='M185.2,163.1c-10.2,10.2-13.2,24.9-21.3,33.1c-8,8-39.4-17.1-63.3-40.7c-23.7-23.9-48.7-55.3-40.7-63.3 c8.1-8.1,22.8-11,33.1-21.3c10.2-10.2-33.5-58.6-40.4-65.5S35.7-2.2,27.8,5.7C-17.5,51-8.1,113.3,60.8,186.3l-0.2,0.1 c1.5,1.5,9.1,8.8,9.1,8.8c72.9,68.9,135.3,78.4,180.6,33c7.9-7.9,7.1-17.8,0.3-24.6C243.8,196.7,195.4,152.9,185.2,163.1z' /%3E%3C/g%3E%3C/svg%3E");
}

p {
    margin-top: 0;
}

p:not(:last-child) {
    margin: 0 0 1rem 0;
}

li:not(:last-child) {
    margin: 0 0 0.5rem 0;
}

ul,
ol,
dl {
    margin: 0 0 2rem 0;
}

ul ul,
ul ol,
ul dl,
ol ul,
ol ol,
ol dl,
dl ul,
dl ol,
dl dl {
    margin-top: 0.5rem;
    margin-bottom: 0;
}

ul {
    padding: 0;
    list-style-type: none;
}

ul li {
    padding-right: 0;
    padding-left: 0;
}

ol {
    padding-left: 1.5rem;
    list-style-type: decimal;
}

ol li {
    padding-left: 0.25rem;
}

hr {
    overflow: visible;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    border-bottom: none;
}

b,
strong {
    font-weight: bolder;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-bottom: 3rem;
}

main:focus {
    outline: none;
}

h1 {
    margin: 0.67em 0;
    font-size: 2em;
}

h1,
.h1 {
    font-size: 2.0272865295rem;
    line-height: 1.2331754607;
    display: block;
    font-weight: bold;
    margin-bottom: 1rem;
}

h2,
.h2 {
    font-size: 1.8020324707rem;
    line-height: 1.3873223933;
    display: block;
    font-weight: bold;
    margin: 0rem 0rem 1rem 0rem;
}

h3,
.h3 {
    font-size: 1.423828125rem;
    line-height: 1.4046639232;
    display: block;
    font-weight: bold;
    margin: 0rem 0rem 0.5rem 0rem;
}

h4,
.h4 {
    font-size: 1.265625rem;
    line-height: 1.1851851852;
    display: block;
    font-weight: bold;
    margin: 0rem 0rem 0.5rem 0rem;
}

h5,
.h5 {
    font-size: 1.265625rem;
    line-height: 1.1851851852;
    display: block;
    font-weight: bold;
    margin: 0rem 0rem 0.5rem 0rem;
    font-style: italic;
    margin: 0rem 0rem 0.5rem 0rem;
}

table {
    min-width: 100%;
    margin: 0rem 0rem 1.5rem 0rem;
    text-align: left;
    border-collapse: collapse;
    background-color: #f3f3f3;
}

table td,
table th,
table p,
table li,
table caption {
    font-size: 1rem;
    line-height: 1.5;
}

table li {
    overflow: hidden;
    position: relative;
    padding-left: calc(7px + 0.75rem);
}

table li:before {
    position: absolute;
    left: 0;
    content: "";
    background: #000;
    display: block;
    text-indent: -0.1em;
    width: 6px;
    height: 6px;
    top: 5.6px;
}

@media (min-width: 768px) {
    table li:before {
        width: 7px;
        height: 7px;
        top: 6.3px;
    }
}

@media (min-width: 992px) {
    table li:before {
        width: 7px;
        height: 7px;
        top: 7.5px;
    }
}

table th,
table td {
    padding: 1rem;
    vertical-align: top;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-collapse: collapse;
}

table th:last-child,
table td:last-child {
    border-right-width: 0;
}

table th {
    background-color: #e6e6e6;
}

table td:only-child {
    border-right: 2px solid #fff;
}

table p,
table ul {
    margin-bottom: 1rem;
}

table p:last-child,
table ul:last-child {
    margin-bottom: 0;
}

table caption {
    margin-bottom: 0.75rem;
    text-align: left;
    color: #535353;
}

table caption .title {
    display: block;
    color: #000;
    font-weight: 700;
}

table caption .subtitle {
    color: #535353;
}

.scrollcontainer {
    margin: 0rem 0rem 2rem 0rem;
    overflow: hidden;
    overflow-x: auto;
}

.scrollcontainer table {
    margin-bottom: 0;
}

.scrollcontainer::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 11px;
    height: 11px;
}

.scrollcontainer::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: #e6e6e6;
}

@media (min-width: 768px) {
    .scrollcontainer th.col-wide {
        width: 40%;
    }
}